<template>
   <v-dialog v-model="permitAnswerDialog" persistent max-width="480">
     <v-card>
       <v-card-title class="headline pa-0">
         <v-toolbar flat>
           <v-toolbar-title>
             {{ $t("card.permit-disposition") }}
           </v-toolbar-title>
           <v-spacer></v-spacer>
           <v-tooltip bottom>
             <template v-slot:activator="{ on, attrs }">
               <v-btn icon @click="permitAnswerDialog = false" v-on="on" v-bind="attrs">
                 <v-icon>{{ iconClose }}</v-icon>
               </v-btn>
             </template>
             <span>{{ $t("tooltip.close-not-save") }}</span>
           </v-tooltip>
         </v-toolbar>
       </v-card-title>
       <v-card-text class="permit-answer pt-4 pb-0">
         <v-row>
           <v-col cols="12" v-if="$v.theAnswer.$error && !$v.theAnswer.required">
             <v-alert dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble bottom--left">
               {{ $t("error.you-must-pick-answer") }}
             </v-alert>
           </v-col>
           <v-col cols="12" class="text-center">
             <input type="radio" id="fat" name="fatfit" value="0" v-model="theAnswer" />
             <input type="radio" id="fot" name="fatfit" value="2" v-model="theAnswer" />
             <input type="radio" id="fit" name="fatfit" value="1" v-model="theAnswer" />
             <div class="text__answer">
               {{ answerResult }}
             </div>
             <div class="mt-6 d-inline-flex justify-space-between align-content-center" style="width:100%;">
               <label class="answer__radio" for="fat">�<span class="answer__radio-label"><br />{{ $t("label.reject")
               }}</span></label>
               <label class="answer__radio" for="fot" id="fotlabel">✍�<span class="answer__radio-label"
                   style="white-space:nowrap;"><br />{{ $t("label.review") }}</span></label>
               <label class="answer__radio" for="fit">��<span class="answer__radio-label"><br />{{ $t("label.agree")
               }}</span></label>
             </div>
           </v-col>
           <v-col cols="12" v-if="$v.comment.$error && !$v.comment.required">
             <v-alert dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble bottom--left">
               {{ $t("error.comment-must-be-filled") }}
             </v-alert>
           </v-col>
           <v-col cols="12" v-if="$v.comment.$error && !$v.comment.minLength">
             <v-alert dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble bottom--left">
               {{
                   `${$t("error.comment-min-length-is")} 10 ${$t(
                     "text.characters"
                   )}`
               }}
             </v-alert>
           </v-col>
           <v-col cols="12" v-if="$v.comment.$error && !$v.comment.maxLength">
             <v-alert dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble bottom--left">
               {{
                   `${$t("error.comment-max-length-is")} 250 ${$t(
                     "text.characters"
                   )}`
               }}
             </v-alert>
           </v-col>
           <v-col cols="12">
             <v-textarea class="font-size-default" rows="3" v-model="comment" name="comment" :label="$t('label.comment')"
               value="" hint="Komentar tambahan"></v-textarea>
           </v-col>
           <v-col cols="12" v-if="$v.passPhrase.$error && !$v.passPhrase.required">
             <v-alert dense type="error" :icon="iconAlert"
               class="modal-alert v-alert--narrow v-alert--bubble bottom--left">
               {{ $t("error.passphrase-must-be-filled") }}
             </v-alert>
           </v-col>
           <v-col cols="12" v-if="theAnswer && theAnswer == 1">
             <v-text-field v-model="passPhrase" :error-messages="passPhraseErrors" :counter="100" label="Passphrase"
               required v-bind:type="passType" v-on:input="$v.passPhrase.$touch()" v-on:blur="$v.passPhrase.$touch()"
               v-on:keyup.enter="submit">
               <v-tooltip slot="append" bottom>
                 <template v-slot:activator="{ on, attrs }">
                   <v-btn icon color="primary" v-on="on" v-bind="attrs" v-on:click="togglePass">
                     <v-icon v-if="passType === 'password'">{{
                         iconEye
                     }}</v-icon>
                     <v-icon v-else>{{ iconEyeClose }}</v-icon>
                   </v-btn>
                 </template>
                 <span v-if="passType === 'password'">{{
                     $t("tooltip.show-pass")
                 }}</span>
                 <span v-else>{{ $t("tooltip.hide-pass") }}</span>
               </v-tooltip>
             </v-text-field>
           </v-col>
         </v-row>
       </v-card-text>
       <v-card-text class="pb-2" v-if="$v.chkAgree.$error && !$v.chkAgree.required">
         <v-alert dense type="error" :icon="iconAlert"
           class="modal-alert v-alert--narrow v-alert--bubble bottom--right bottom--right-20-percent">
           {{ $t("error.you-must-agree-to-process") }}
         </v-alert>
       </v-card-text>
       <v-card-text class="d-flex justify-end mr-2 pt-0" v-if="theAnswer && theAnswer != 1">
         <label class="avoore-checkbox path d-block" v-bind:class="{ 'theme--dark': $vuetify.theme.dark }">
           <input type="checkbox" class="d-inline-flex" v-model="chkAgree" :value="true" id="chk-agree-permit" />
           <svg viewBox="0 0 21 21">
             <path
               d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
             </path>
           </svg>
           <span for="chk-agree-permit" class="checkbox-title">{{
               $t("label.agree-to-process")
           }}</span>
         </label>
       </v-card-text>
       <v-card-actions class="pb-4">
         <v-spacer></v-spacer>
 
         <v-btn outlined color="red darken-1" @click="permitAnswerDialog = false" v-if="!ajaxOnProcess">
           <v-icon class="mr-2">{{ iconClose }}</v-icon>
           {{ $t("button.cancel") }}
         </v-btn>
         <v-btn outlined :loading="permitProcess === 'processing'" color="primary darken-1" @click="submit"
           :disabled="!theAnswer">
           <v-icon class="mr-2">{{ iconDisposition }}</v-icon>
           {{ $t("button.do-disposition") }}
         </v-btn>
       </v-card-actions>
     </v-card>
   </v-dialog>
 </template>
 <script>
 import { mapGetters, mapActions, mapState } from "vuex";
 import {
   mdiAccountArrowRightOutline,
   mdiAlertCircleOutline,
   mdiClose,
   mdiCellphoneMessage,
   mdiTimerSand,
   mdiEyeOff,
   mdiEye,
 } from "@mdi/js";
 import { validationMixin } from "vuelidate";
 const {
   required,
   minLength,
   maxLength,
   requiredIf,
 } = require("vuelidate/lib/validators");
 
 const rejectReview = [0, 2];
 
 export default {
   name: "disposition-modal",
 
   props: {
     permitId: { type: String },
     memoId: { type: String },
   },
   data: () => ({
     iconDisposition: mdiAccountArrowRightOutline,
     iconAlert: mdiAlertCircleOutline,
     iconClose: mdiClose,
     iconSms: mdiCellphoneMessage,
     iconTimer: mdiTimerSand,
     theAnswer: undefined,
     comment: undefined,
     chkAgree: undefined,
     dispositionAlert: true,
     token: "",
     passPhrase: "",
     passType: "password",
     iconEyeClose: mdiEyeOff,
     iconEye: mdiEye,
     ajaxOnProcess: false,
   }),
   mixins: [validationMixin],
   validations: {
     passPhrase: {
       required: requiredIf(function () {
         return this.theAnswer == 1;
       }),
       minLength: minLength(8), maxLength: maxLength(100)
     },
     theAnswer: { required },
     comment: {
       required,
       minLength: minLength(10),
       maxLength: maxLength(250),
     },
     // token: {
     //   required: requiredIf(function () {
     //     return this.theAnswer == 1;
     //   }),
     // },
     chkAgree: {
       required: requiredIf(function () {
         return rejectReview.includes(parseInt(this.theAnswer));
       }),
     },
   },
   created() {
     // this.getDataResource({ source: "disposition-recipients", bgProcess: true });
   },
   computed: {
     ...mapGetters("resources", [
       "dispositionMessages",
       "dispositionRecipients",
     ]),
     ...mapState("inbox", ["permitTokenIsValid", "permitProcess"]),
     ...mapState("user", ["tokenCountDown"]),
     answerResult() {
       if (!this.theAnswer) return this.$t("text.not-answered");
       if (this.theAnswer == 1) return this.$t("text.agree");
       if (this.theAnswer == 2) return this.$t("text.review");
       return this.$t("text.reject");
     },
     permitAnswerDialog: {
       
       get() {
         return this.$store.state.inbox.permitAnswerDialog;
       },
       set(val) {
          if(this.ajaxOnProcess) return;
          this.$store.commit("inbox/SET_PERMIT_ANSWER_DIALOG", val);
       },
     },
     perColumnRecipientsRow() {
       if (!this.dispositionRecipients) return 5;
       if (this.isEventOrOdd(this.dispositionRecipients.length) == "even") {
         return this.dispositionRecipients.length / 2;
       }
       return this.dispositionRecipients.length / 2 + 1;
     },
 
     firstColumnRecipients() {
       if (!this.dispositionRecipients) return undefined;
       return this.dispositionRecipients.slice(0, this.perColumnRecipientsRow);
     },
     secondColumnRecipients() {
       if (!this.dispositionRecipients) return undefined;
       return this.dispositionRecipients.slice(
         this.perColumnRecipientsRow,
         this.dispositionRecipients.length
       );
     },
     passPhraseErrors() {
       const errors = [];
       if (!this.$v.passPhrase.$dirty) return errors;
       !this.$v.passPhrase.maxLength &&
         errors.push(
           `${this.$t("error.passphrase-max-length")} 100 ${this.$t(
             "text.characters"
           )}`
         );
       !this.$v.passPhrase.minLength &&
         errors.push(
           `${this.$t("error.passphrase-min-length")} 8 ${this.$t(
             "text.characters"
           )}`
         );
       !this.$v.passPhrase.required &&
         errors.push(`${this.$t("error.passphrase-must-be-filled")}`);
       return errors;
     },
   },
   watch: {
     permitAnswerDialog(val) {
       if (!val) return;
       this.$v.$reset();
       this.theAnswer = undefined;
       this.comment = undefined;
       this.chkAgree = undefined;
       this.token = "";
       this.setPermitTokenIsValid(undefined);
       this.setPermitProcess('not-started');
       this.setCountDownTime(undefined);
     },
     theAnswer(val, oldVal) {
       if (val === oldVal) return;
       this.$v.$reset();
       this.token = "";
       this.chkAgree = undefined;
     },
     tokenCountDown: {
       handler: function (val) {
         if (!val) return;
         if (val <= 0) return this.setCountDownTime(undefined);
         setTimeout(() => {
           if (!val) return;
           if (val) this.setCountDownTime(val - 1);
         }, 1000);
       },
       deep: true,
     },
     permitProcess(val) {
       if (!val) return;
       if (val === 'success') this.permitAnswerDialog = false;
     }
   },
   methods: {
     ...mapActions("resources", ["getDataResource", "setDispositionRecipients"]),
     ...mapActions("inbox", [
       "submitMyAnswer",
       "requestTelegramToken",
       "setPermitTokenIsValid",
       "setPermitProcess",
     ]),
     ...mapActions("user", ["setCountDownTime"]),
     getOtp() {
       //apakah dilimit berdasarkan jawaban?
       if (!this.permitId) return console.error("Tidak ada Id Memo");
       this.setCountDownTime(120);
       this.requestTelegramToken({
         formData: null,
         permitId: this.permitId,
       });
     },
     submit() {
       if (this.ajaxOnProcess) return;
       this.$v.$touch();
       setTimeout(() => {
          if (this.$v.$invalid) return;
          this.ajaxOnProcess = true;
 
         let formData = {
           comment: this.comment,
           is_approved: this.theAnswer,
           passphrase: this.passPhrase
           // otp: this.token,
         };
 
         /*
         kode di bawah ada param memoId
         memoId pada laman disposition adalah DispositionId
         pada laman inbox = memoId sebenarnya.
         karena modal ini berbagi pakai jadinya disulap dalam param memoId semuanya
         */
         if (!this.permitId) return console.error("Tidak ada Id Memo");     
         
         this.submitMyAnswer({
           formData: formData,
           permitId: this.permitId,
           memoId: this.memoId,
         });
 
         // this.$nextTick(() => {
         //   console.log(this.permitProcess);
         //   if (this.permitProcess ==='success') this.permitAnswerDialog = false;
         // });
         this.ajaxOnProcess = false;
       }, 300);
     },
     requestToken() {
       console.log("token");
     },
     togglePass() {
       this.passType = this.passType === "password" ? "text" : "password";
     },
   },
 };
 </script>
 <style lang="scss">
 .modal-alert {
   margin-bottom: 0 !important;
 }
 
 .py-0 {
   padding-top: 0 !important;
   padding-bottom: 0 !important;
 }
 
 .px-24 {
   padding-left: 24px !important;
   padding-right: 24px !important;
 }
 </style>